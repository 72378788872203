import { Controller } from '@hotwired/stimulus';

import Uppy from '@uppy/core';
import ThumbnailGenerator from '@uppy/thumbnail-generator';
import XHRUpload from '@uppy/xhr-upload';
import DragDrop from '@uppy/drag-drop';
import Alpine from 'alpinejs';

document.addEventListener('alpine:init', () => {
  Alpine.store('image_upload', {
    in_progress: false,
  });
});

export default class extends Controller {
  static targets = [
    'previewContainer', 'previewImage', 'previewFileName', 'previewFileSize',
    'hiddenInput', 'dragDrop', 'removeInput', 'submitButton'
  ];

  connect() {
    this.uppy = new Uppy({
      debug: true,
      autoProceed: true,
      restrictions: {
        maxNumberOfFiles: 1,
        allowedFileTypes: ['image/*'],
      },
      locale: {
        strings: {
          browse: 'Click',
          dropHereOr: '%{browse} to upload or drag and drop',
        },
      },
    })
      .use(ThumbnailGenerator, {
        thumbnailHeight: 600,
      })
      .use(XHRUpload, {
        endpoint: '/upload',
      })
      .use(DragDrop, {
        target: this.dragDropTarget,
        note: 'PNG, JPG or WEBP',
      });

    this.uppy.on('thumbnail:generated', (file, preview) => {
      const fileSize = Math.round(file.size / 1000) + ' KB';
      this.fileId = file.id;
      this.updatePreview(preview, file.name, fileSize);
    });

    this.uppy.on('upload-success', (file, response) => {
      const uploadedFileData = response.body.data;
      document.getElementById('status-bar').classList.add('hidden');
      this.updateData(file, JSON.stringify(uploadedFileData));
      Alpine.store('image_upload').in_progress = false;
      this.enableButton()
    });

    this.uppy.on('upload', (_data) => {
      document.getElementById('status-bar').classList.remove('hidden');
      document.getElementById('status-bar-percentage').style.width = '0%';
      Alpine.store('image_upload').in_progress = true;
    });

    this.uppy.on('upload-progress', (file, progress) => {
      let percentage = Math.round((progress.bytesUploaded / progress.bytesTotal) * 100);
      let fileSize = Math.round(file.size / 1000);
      document.getElementById('status-bar-percentage').style.width = percentage + '%';
      document.getElementById('status-bar-percentage-text').innerHTML = percentage + '%';
      document.getElementById('status-bar-file-name').innerHTML = file.name;
      document.getElementById('status-bar-file-size').innerHTML = fileSize + ' KB';
    });
  }

  updatePreview(preview, fileName, fileSize) {
    this.previewImageTarget.src = preview;
    if (this.hasPreviewFileNameTarget) {
      const truncateSize = parseInt(this.previewFileNameTarget.dataset.fileNameSize);
      this.previewFileNameTarget.innerHTML = this.truncateString(fileName, truncateSize);
    }
    if (this.hasPreviewFileSizeTarget) {
      this.previewFileSizeTarget.innerHTML = fileSize;
    }
    this.previewContainerTarget.classList.remove('hidden');
    this.dragDropTarget.style.display = 'none';
  }

  updateData(_file, data) {
    this.hiddenInputTarget.value = data;
    this.removeInputTarget.value = 'false';
  }

  remove(e) {
    e.preventDefault();
    this.previewContainerTarget.classList.add('hidden');
    this.hiddenInputTarget.value = '';

    this.dragDropTarget.style.display = 'inline-block';

    this.uppy.removeFile(this.fileId);

    this.removeInputTarget.value = 'true';
  }

  changeImage(e) {
    e.preventDefault();

    this.remove(e);
  }

  truncateString(str, num) {
    if (num === 0 || str.length <= num) {
      return str
    }

    return str.slice(0, num) + '...'

  }

  enableButton() {
    if (!this.hasSubmitButtonTarget) return;

    this.submitButtonTarget.disabled = false;
  }
}
