import { Controller } from '@hotwired/stimulus';
import { Modal } from 'tailwindcss-stimulus-components';

export default class extends Modal {
  static targets = ['newContent'];

  newContentTargetConnected() {
    this.open()
  }
}
